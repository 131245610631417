import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const FertilityAnswersLandingPage = () => (
  <PhysicianLandingPage
    physician="FertilityAnswers"
    institution="FertilityAnswers"
    referralCode="FERTILITYANSWERS"
    physicianURL="https://fertility.medanswers.com/"
  />
)

export default FertilityAnswersLandingPage
